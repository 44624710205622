<script>
import {
	mdiCalendarOutline,
	mdiHistory
} from "@mdi/js"
import {
	getHolidays,
	getVacationApplicationsForManager,
} from "@/services/api/requests"
import CardApplicationManager from "@/components/Card/CardApplicationManager.vue"
import ChartGantt from "@/components/ChartGantt.vue"
import {mapGetters} from "vuex";
import {sortAplications} from "@/services/helpers";

export default {
	name: "ManagerView",
	components: {ChartGantt, CardApplicationManager},
	data() {
		return {
			applicationsCurrent: [],
			applicationsPrevious: [],
			loadingApplications: false,
			currentYear: this.$store.state['App'].currentYear,
			holidays: [],
			icons: {
				history: mdiHistory,
				calendar: mdiCalendarOutline,
			},
		}
	},
	computed: {
		showNoCurrentApplications() {
			return !this.loadingApplications && this.applicationsCurrent.length === 0
		},
		...mapGetters("User",{
			userRole: "getRole",
		})
	},
	async created() {
		await this.fetchApplications()
	},
	methods: {
		async fetchApplications() {
			this.loadingApplications = true
			const { results } = await getVacationApplicationsForManager()
			const applicationsByYear = new Map()
			const applicationsCurrent = []
			results.forEach((application) => {
				if (application.year === this.currentYear) {
					applicationsCurrent.push(application)
				} else {
					if (!applicationsByYear.has(application.year)) {
						applicationsByYear.set(application.year, [])
					}
					applicationsByYear.get(application.year).push(application)
				}
			})
			this.applicationsCurrent = sortAplications(applicationsCurrent, this.userRole)
			this.applicationsPrevious = applicationsByYear
			await this.fetchHolidays()
			this.loadingApplications = false
		},
		async fetchHolidays() {
			const { holiday_list } = await getHolidays(this.currentYear)
			this.holidays = holiday_list
		},
		onUpdate() {
			this.fetchApplications()
			this.$refs.gantt.fetchDiagram()
		},
	},
}
</script>

<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<div class="d-flex justify-space-between">
					<h1>Заявки сотрудников на отпуск в {{ currentYear }} году</h1>
				</div>
			</v-col>
		</v-row>
		<ChartGantt ref="gantt" />
		<v-row>
			<v-col v-if="loadingApplications">
				<v-progress-linear
					indeterminate
					color="primary"
				/>
				<p class="text-center">
					Загрузка...
				</p>
			</v-col>
			<v-col
				v-else-if="showNoCurrentApplications"
				cols="12"
			>
				<v-card
					outlined
				>
					<v-card-title>
						<h3>Нет заявок на отпуск в текущем году</h3>
					</v-card-title>
					<v-card-text>
						<p>У вас настроены уведомления на вашу электронную почту о новых заявках на отпуск</p>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col v-else>
				<CardApplicationManager
					v-for="application in applicationsCurrent"
					:key="application.id"
					:application="application"
					:holidays="holidays"
					:current-year="currentYear"
					@update="onUpdate"
				/>
			</v-col>
			<v-col cols="12">
				<template v-for="[year, applications] in applicationsPrevious">
					<h2 :key="`${year}`" class="pt-10 mb-5">
						Заявки на отпуск в {{ year }} году
					</h2>
					<CardApplicationManager
						v-for="(application) in applications"
						:key="application.id"
						:application="application"
						:show-actions="false"
						:holidays="holidays"
						:current-year="currentYear"
						@update="onUpdate"
					/>
				</template>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped lang="scss">
</style>