<script>
import {dictStatuses} from "@/services/mappers"
import {postAcceptVacationApplication} from "@/services/api/requests";
import DialogWrapper from "@/components/Dialog/DialogWrapper.vue";

export default {
	name: "BtnApplicationAccept",
	components: {DialogWrapper},
	props: {
		application: {
			type: Object,
			required: true,
		},
		block: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dictStatuses,
			comment: "",
		}
	},
	computed: {
		status() {
			return this.application.status
		},
	},
	methods: {
		async acceptApplication(application) {
			this.$emit("loading", true)
			try {
				await postAcceptVacationApplication(application.id, {
					comment: this.comment || undefined,
				})
				this.$emit("update")
			} catch (e) {
				console.error(e)
			}
			this.$emit("loading", false)
		},
	},
}
</script>

<template>
	<v-col
		v-if="status === dictStatuses.NEW || !application.status"
		cols="12"
		sm="auto"
	>
		<DialogWrapper
			title="Подтвердить заявку"
		>
			<template #activator="{on, attrs}">
				<v-btn
					rounded
					depressed
					block
					color="success"
					class="px-6"
					:disabled="loading"
					v-bind="attrs"
					v-on="on"
				>
					Подтвердить
				</v-btn>
			</template>
			<template #text>
				<v-text-field
					v-model="comment"
					hide-details
					label="Комментарий"
					clearable
				/>
			</template>
			<template	#btn>
				<v-btn
					rounded
					depressed
					block
					color="success"
					class="px-6"
					:disabled="loading"
					@click="acceptApplication(application)"
				>
					Подтвердить
				</v-btn>
			</template>
		</DialogWrapper>
	</v-col>
</template>