<script>
import VueMermaidString from "vue-mermaid-string"
import {mapState} from "vuex"
import {getVacationApplicationGantt} from "@/services/api/requests"

export default {
	name: "ChartGantt",
	components: {
		VueMermaidString,
	},
	data() {
		return {
			showGantt: false,
			loading: false,
			diagram: "",
		}
	},
	computed: {
		...mapState("App", {appWidth: "width"}),
	},
	watch: {
		async appWidth() {
			if (!this.showGantt) return;
			this.showGantt = false;
			await this.$nextTick();
			this.showGantt = true;
		},
		async showGantt() {
			if (!this.showGantt) return;
			await this.$nextTick();
			this.fixSVG();
			this.fixSections();
		},
	},
	async mounted() {
		await this.fetchDiagram();
	},
	methods: {
		async fetchDiagram() {
			this.diagram = "";
			this.showGantt = false;
			this.loading = true;
			try {
				this.diagram = await getVacationApplicationGantt();
			} catch (e) {
				this.diagram = "";
			} finally {
				if (this.diagram)
					this.showGantt = true;
				this.loading = false;
			}
		},
		fixSVG() {
			const mermaidDiv = document.querySelector('.mermaid');
			const svgElement = mermaidDiv.querySelector('svg');
			svgElement.removeAttribute('viewBox');
		},
		fixSections() {
			const xpath = "//*[contains(@class, 'section') and (substring(@class, string-length(@class) - 0) = '1' or substring(@class, string-length(@class) - 0) = '3' or substring(@class, string-length(@class) - 0) = '5' or substring(@class, string-length(@class) - 0) = '7' or substring(@class, string-length(@class) - 0) = '9')]";
			let results = [];
			let iterator = document.evaluate(xpath, document, null, XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE, null);
			for (let i = 0; i < iterator.snapshotLength; i++) {
				results.push(iterator.snapshotItem(i));
			}
			results.forEach(element => {
				element.setAttribute('x', '-40');
			});
		}
	},
}
</script>

<template>
	<div
		v-if="diagram"
		class="gantt-wrapper my-5"
	>
		<VueMermaidString
			v-if="showGantt"
			:value="diagram"
			class="mermaid"
		/>
	</div>
</template>

<style lang="scss">
.gantt-wrapper {
	overflow-x: auto;
}
.mermaid {
	min-width: 1000px;
	svg {
		overflow: visible;
		padding-left: 40px;
	}
}
.sectionTitle {
	transform: translateX(-40px);
}
.titleText {
	display: none;
}
.grid .tick {
	stroke: lightgrey !important;
	opacity: 0.3 !important;
}
.grid path {
	stroke-width: 0 !important;
}
.taskText,
.taskTextOutsideRight,
.taskTextOutsideLeft,
.titleText,
.grid .tick text,
.sectionTitle {
	font-family: "Mont", sans-serif !important;
}
$number-of-classes: 20;
@for $i from 0 through $number-of-classes {
  .task#{$i} {
    fill: #00A5CF !important;
    stroke: #00A5CF !important;
  }
	.active#{$i} {
		fill: #e0e0e0 !important;
		stroke: #e0e0e0 !important;
	}
	.done#{$i} {
		fill: #E28413 !important;
		stroke: #E28413 !important;
	}
	.crit#{$i} {
		fill: #226F54 !important;
		stroke: #226F54 !important;
	}
	.section#{$i} {
		max-width: 1000px;
		@if $i % 2 == 0 {
			fill: #f0f0f0 !important;
		} @else {
			fill: #cecece !important;
		}
	}
}
</style>